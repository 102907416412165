import { useEffect } from 'react';
import { WindowLocation } from '@reach/router';
import { useAtomValue, useSetAtom } from 'jotai';

import { useAppContext } from '@contexts/AppContext';
import { AbsPage } from '@src/modules/AnnualBoilerService/Abs';
import PromoService from '@services/PromoService';
import useQuote from '@hooks/useQuote';
import { CoverType } from '@entities/enums';
import {
  boilerDetailsAtom,
  excessAtom,
  isAbsAtom,
  isAgentAtom,
  isBeyondAtom,
  isHomeRecoverAtom,
  selectedCoverTypeAtom,
} from '@src/store/store';

interface Props {
  location: WindowLocation;
}

const Abs = (props: Props) => {
  const { verifyNoSignupInLocalStorage, promo } = useAppContext();

  const excess = useAtomValue(excessAtom);
  const boilerDetails = useAtomValue(boilerDetailsAtom);
  const setSelectedCoverType = useSetAtom(selectedCoverTypeAtom);
  const setExcess = useSetAtom(excessAtom);
  const setIsHomeRecover = useSetAtom(isHomeRecoverAtom);
  const setIsBeyond = useSetAtom(isBeyondAtom);
  const setIsAbs = useSetAtom(isAbsAtom);
  const setIsAgent = useSetAtom(isAgentAtom);

  const { quotes } = useQuote({
    promo,
    excess,
    boilerAge: parseInt(boilerDetails.boilerAge),
  });

  const absQuote = quotes.find((quote) => quote.coverType === CoverType.ABS);

  useEffect(() => {
    verifyNoSignupInLocalStorage();
    setSelectedCoverType(absQuote?.coverType);
    setExcess(0);
    setIsHomeRecover(false);
    setIsBeyond(false);
    setIsAbs(true);
    setIsAgent(false);
  }, [promo, verifyNoSignupInLocalStorage]);

  useEffect(() => {
    PromoService.handlePromoFromUrl();
  }, []);

  return <AbsPage {...props} quote={absQuote} />;
};

export default Abs;
